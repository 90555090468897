import { Vehicle } from "../graphql/generated/graphql";
import { CURRENCY_KEYS } from "./constants";

export const AVG_CONVERTION_RATE = 550; // PENDING: get this rate from an API

export function convertPriceToCRC(price: number, currency: string) {
  if (currency === CURRENCY_KEYS.USD) {
    return price * AVG_CONVERTION_RATE;
  }

  return price;
}
