import Image from "next/image";
import { useEffect, useState } from "react";
import { classNames } from "../utils/formatters";
import Link from "next/link";

import { bannersData as data } from "../utils/constants";
import { event } from "../lib/gtag";

export default function BannnerCTA2({
  singleBanner,
}: {
  singleBanner?: boolean;
}) {
  const [bannerIdx, setBannerIdx] = useState(0);
  function SanitizeBannerData(bannerData: typeof data) {
    const myBannerData = bannerData;
    // filter to show only the ones with the flag alwaysShown
    myBannerData.filter((item) => item.alwaysShown === true);

    //only keep the first 3 of them
    if (myBannerData.length > 3) return myBannerData.slice(0, 3);
    return myBannerData;
  }
  //
  let bannerData = SanitizeBannerData(data);

  useEffect(() => {
    const interval = setInterval(() => {
      setBannerIdx(bannerIdx === bannerData.length - 1 ? 0 : bannerIdx + 1);
    }, 6000);
    return () => clearInterval(interval);
  }, [bannerIdx, bannerData.length]);

  function getBannerClass(bannerIdx, idx) {
    let bannerClass = "flex flex-col";

    if (bannerIdx !== idx && !singleBanner) {
      bannerClass = "hidden sm:flex flex-col";
    } else if (bannerIdx !== idx && singleBanner) {
      bannerClass = "hidden";
    }

    return bannerClass;
  }

  function trackBannerClick(trackId: string) {
    event({
      action: "click_banner",
      category: "Ads",
      label: trackId,
    });
  }

  return null;

  // return (
  //   <div
  //     className={classNames(
  //       "mx-auto max-w-7xl text-center",
  //       !singleBanner ? "sm:px-4" : ""
  //     )}
  //   >
  //     {/* <p className="text-sm text-gray-500 mb-2">Espacio publicitario</p> */}

  //     <div className=" text-left">
  //       <div
  //         className={classNames(
  //           "grid gap-y-4 gap-x-2",
  //           singleBanner
  //             ? "grid-cols-1"
  //             : `grid-cols-1 sm:grid-cols-${
  //                 bannerData.length === 3 ? "3" : "2"
  //               } md:grid-cols-${bannerData.length === 3 ? "3" : "4"}`
  //         )}
  //       >
  //         {bannerData.map((banner, idx) => {
  //           const { id, image, link, linkLabel, trackId } = banner;
  //           return (
  //             <div
  //               key={idx}
  //               className={classNames(
  //                 "group relative cursor-pointer pb-2 border border-gray-100 sm:rounded-md shadow-md bg-gray-100 ",
  //                 getBannerClass(bannerIdx, idx)
  //               )}
  //             >
  //               <div className="relative w-full min-h-60 bg-gray-200 aspect-w-4 aspect-h-2  overflow-hidden group-hover:opacity-75 ">
  //                 <div className="h-full">
  //                   <Link
  //                     href={link}
  //                     target="_blank"
  //                     onClick={() => trackBannerClick(trackId)}
  //                   >
  //                     <div className="absolute h-full w-full">
  //                       <Image
  //                         fill
  //                         style={{
  //                           objectFit: "cover",
  //                         }}
  //                         sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
  //                         src={image}
  //                         alt={`banner`}
  //                         className={classNames(
  //                           "w-full h-full object-center object-cover sm:rounded-t-md"
  //                         )}
  //                       />
  //                     </div>
  //                   </Link>
  //                 </div>
  //               </div>
  //               <div className="flex items-center justify-end gap-x-1.5 mr-1 pt-2 px-2">
  //                 <Link
  //                   href={link}
  //                   target="_blank"
  //                   onClick={() => trackBannerClick(trackId)}
  //                 >
  //                   <span className="text-sm font-semibold leading-6 text-orange-500 hover:text-orange-400">
  //                     {linkLabel || "Quiero saber más"}{" "}
  //                     <span aria-hidden="true">→</span>
  //                   </span>
  //                 </Link>
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   </div>
  // );
}
