import Image from "next/image";
import Link from "next/link";
import { classNames } from "../../utils/formatters";

export const COLLECTION_DESCRIPTION = `Nuestro servicio exclusivo de asesoría en ventas de autos premium y de lujo. Nuestra promesa de servicio es privacidad, seguridad y confianza. Nos encargamos de toda la gestión de venta de su auto, a través de un proceso certificado y en total comunicación con usted.`;

export default function CollectionBanner({ dark }: { dark?: boolean }) {
  return (
    <div className="flex items-center justify-between">
      <div>
        <Image
          src={
            dark
              ? "/collection_white_transparent_bg.png"
              : "/collection_transparent_bg.png"
          }
          alt="Logo Movi Collection"
          width={180}
          height={41.04}
          priority
          loading="eager"
        />
        <p
          className={classNames(
            "mt-2 text-base  font-normal ",
            dark ? "text-gray-300" : "text-gray-500"
          )}
        >
          {COLLECTION_DESCRIPTION}
          <Link
            href="/collection-acerca-de"
            // onClick={() => trackBannerClick(trackId)}
            className="ml-1"
          >
            <span className="font-semibold leading-6 text-orange-500 hover:text-orange-400">
              Quiero saber más
              <span aria-hidden="true">→</span>
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
}
