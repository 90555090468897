import { ObjectID } from "bson";
import { CURRENCY_KEYS, FILTER_CATEGORIES } from "./constants";
import { AVG_CONVERTION_RATE, convertPriceToCRC } from "./currency";

export function queryToObject(query: string) {
  const urlParams = new URLSearchParams(query);
  return Object.fromEntries(urlParams);
}

export function getFilterValue(key, queryObject) {
  const filter = FILTER_CATEGORIES[key]?.filter;
  const query = queryObject[key].split(",");
  if (filter) {
    let value = "";
    query.forEach((q) => {
      value = value ? `${value},${filter[q].label}` : filter[q].label;
    });

    return value;
  } else {
    return queryObject[key];
  }
}

export function getFavoriteSearchQuerystringFromVehicleData(vehicle) {
  const {
    // user,
    category,
    condition,
    fuel,
    transmission,
    drivetrain,
    brand,
    model,
    year,
    price,
    currency,
    // location,
    // cc,
    // cylinders,
    // kwh,
    // range,
  } = vehicle;

  const queryObject = {
    category,
    condition,
    fuel,
    transmission,
    drivetrain,
    brand,
    model,
    yearMin: year - 5,
    yearMax: year + 5,
    priceMin: currency === CURRENCY_KEYS.USD ? price - 5000 : price - 3000000,
    priceMax: currency === CURRENCY_KEYS.USD ? price + 5000 : price + 3000000,
    // price,
    // currency,
    // province: location.province,

    // // province: [location.province],
  };

  return queryObject;
}

export function getFavoriteSearchesByVehiclePayload(vehicle) {
  const {
    category,
    condition,
    fuel,
    transmission,
    drivetrain,
    brand,
    model,
    year,
    price,
    currency,
    location,
    // cc,
    // cylinders,
    // kwh,
    // range,
  } = vehicle;

  const filters = {
    category,
    condition,
    fuel,
    transmission,
    drivetrain,
    brand,
    model,
    year,
    price,
    currency,
    province: location.province,
  };

  return filters;
}

export function generateQueryString(queryObject: TQueryString) {
  return Object.keys(queryObject)
    .filter((k) => k !== "page")
    .map((key) => key + "=" + queryObject[key])
    .join("&");
}

export async function reverseFavoriteSearch(
  FavoriteSearchModel,
  vehicle,
  ownerId?: string
) {
  const price = convertPriceToCRC(vehicle.price, vehicle.currency);

  const searches = await FavoriteSearchModel.aggregate([
    {
      $search: {
        index: "favorite_search_search",
        // moreLikeThis: {
        //   like: {
        //     filters: {
        //       search: [vehicle.brand, vehicle.model],
        //       ...vehicle,
        //     },
        //   },
        // },
        compound: {
          should: [
            {
              text: {
                query: vehicle.model,
                path: "filters.model",
                score: { boost: { value: 5 } },
              },
            },
            {
              text: {
                query: vehicle.model,
                path: "filters.search",
                score: { boost: { value: 5 } },
              },
            },
            {
              text: {
                query: vehicle.category,
                path: "filters.category",
                score: { boost: { value: 4 } },
              },
            },
            {
              text: {
                query: vehicle.transmission,
                path: "filters.transmission",
                score: { boost: { value: 4 } },
              },
            },
            {
              text: {
                query: vehicle.transmission,
                path: "filters.search",
                score: { boost: { value: 4 } },
              },
            },
            {
              text: {
                query: vehicle.brand,
                path: "filters.brand",
                score: { boost: { value: 4 } },
              },
            },
            {
              text: {
                query: vehicle.brand,
                path: "filters.search",
                score: { boost: { value: 4 } },
              },
            },
            {
              text: {
                query: vehicle.condition,
                path: "filters.condition",
                score: { boost: { value: 3 } },
              },
            },
            {
              text: {
                query: vehicle.drivetrain,
                path: "filters.drivetrain",
                score: { boost: { value: 2 } },
              },
            },
            {
              text: {
                query: vehicle.fuel,
                path: "filters.fuel",
                score: { boost: { value: 2 } },
              },
            },
          ],
        },
      },
    },
    {
      $addFields: {
        score: { $meta: "searchScore" },
      },
    },
    {
      $match: {
        score: { $gte: 0.7 },
      },
    },
    {
      $match: {
        notify: ownerId ? { $in: [true, false] } : true,
      },
    },
    {
      $match: ownerId
        ? {
            userId: new ObjectID(ownerId), // ownerId is defined when calling getFavoriteSearchesByVehicle (used to show if a vehicle is already in YOUR favorite searches)
          }
        : {
            userId: { $ne: new ObjectID(vehicle.userId) }, // We want to notify everyone BUT the owner
          },
    },
    {
      $addFields: {
        ["filters.convertedPriceMin"]: {
          $cond: [
            { $eq: ["$filters.currency", CURRENCY_KEYS.USD] },
            { $multiply: ["$filters.priceMin", AVG_CONVERTION_RATE] },
            "$filters.priceMin",
          ],
        },
        ["filters.convertedPriceMax"]: {
          $cond: [
            { $eq: ["$filters.currency", CURRENCY_KEYS.USD] },
            { $multiply: ["$filters.priceMax", AVG_CONVERTION_RATE] },
            "$filters.priceMax",
          ],
        },
      },
    },
    {
      $match: {
        $or: [
          {
            "filters.convertedPriceMin": {
              $exists: false,
            },
          },
          {
            "filters.convertedPriceMin": {
              $lte: price,
            },
          },
        ],
      },
    },
    {
      $match: {
        $or: [
          {
            "filters.convertedPriceMax": {
              $exists: false,
            },
          },
          {
            "filters.convertedPriceMax": {
              $gte: price,
            },
          },
        ],
      },
    },
    {
      $match: {
        $or: [
          {
            "filters.yearMin": {
              $exists: false,
            },
          },
          {
            "filters.yearMin": { $lte: vehicle.year },
          },
        ],
      },
    },
    {
      $match: {
        $or: [
          {
            "filters.yearMax": {
              $exists: false,
            },
          },
          {
            "filters.yearMax": { $gte: vehicle.year },
          },
        ],
      },
    },
    {
      $lookup: {
        from: "users",
        localField: "userId",
        foreignField: "_id",
        as: "user",
      },
    },
    { $unwind: "$user" },
    {
      $project: {
        _id: 1,
        userId: 1,
        query: 1,
        notify: 1,
        filters: 1,
        user: 1,
        score: { $meta: "searchScore" },
      },
    },
  ]);

  // console.log(JSON.stringify(searches, null, 2));
  return searches;
}
