import { useToast } from "../context/toast";
import {
  useAddFavoriteSearchMutation,
  useDeleteFavoriteSearchMutation,
  useUpdateFavoriteSearchMutation,
} from "../graphql/generated/graphql";
import { generateQueryString } from "../utils/favoriteSearches";

export default function useFavoriteSearchMutations(
  refetchVehicleSearches?: () => void
) {
  const { toggleToast } = useToast();

  const [addUserFavoriteSearch, { loading: savingFavoriteSearch }] =
    useAddFavoriteSearchMutation({
      update(cache, { data }) {
        const { addFavoriteSearch } = data;
        cache.modify({
          fields: {
            getUserFavoriteSearches(existingFavoriteSearches = []) {
              return [addFavoriteSearch, ...existingFavoriteSearches];
            },
          },
        });

        if (refetchVehicleSearches) {
          refetchVehicleSearches();
        }
      },
      onError() {
        toggleToast({
          open: true,
          title: "Error",
          description: "Hubo un error al guardar la búsqueda favorita",
          type: "error",
        });
      },
    });

  const [updateUserFavoriteSearch, { loading: updatingFavoriteSearch }] =
    useUpdateFavoriteSearchMutation({
      update(cache, { data }) {
        const { updateFavoriteSearch } = data;
        cache.modify({
          fields: {
            getUserFavoriteSearches(existingFavoriteSearches = []) {
              return [updateFavoriteSearch, ...existingFavoriteSearches];
            },
          },
        });

        if (refetchVehicleSearches) {
          refetchVehicleSearches();
        }
      },
      onError() {
        toggleToast({
          open: true,
          title: "Error",
          description: "Hubo un error al actualizar la búsqueda favorita",
          type: "error",
        });
      },
    });

  const [deleteUserFavoriteSearch, { loading: deletingFavoriteSearch }] =
    useDeleteFavoriteSearchMutation({
      update(cache, { data }) {
        const { deleteFavoriteSearch } = data;
        const normalizedId = cache.identify({
          id: deleteFavoriteSearch._id,
          __typename: "FavoriteSearch",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onError() {
        toggleToast({
          open: true,
          title: "Error",
          description: "Hubo un error al remover la búsqueda favorita",
          type: "error",
        });
      },
    });

  function addFavoriteSearch(searchData, notify?: boolean) {
    const variables = {
      query: generateQueryString(searchData),
      notify,
    };
    addUserFavoriteSearch({
      variables,
    });
  }

  function updateFavoriteSearch(_id: string, notify?: boolean) {
    const variables = {
      _id,
      notify,
    };
    updateUserFavoriteSearch({
      variables,
    });
  }

  function removeFavoriteSearch(_id) {
    const variables = {
      _id,
    };
    deleteUserFavoriteSearch({
      variables,
    });
  }

  return {
    addFavoriteSearch,
    updateFavoriteSearch,
    removeFavoriteSearch,
    savingFavoriteSearch,
    updatingFavoriteSearch,
    deletingFavoriteSearch,
  };
}
